module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/janbiardzki/Projects/zoaarchitekci/src/layouts/MainLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/janbiardzki/Projects/zoaarchitekci/src/intl","languages":["en","fr","pl"],"defaultLanguage":"pl","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
