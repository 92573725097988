// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-pracownia-jsx": () => import("./../../../src/pages/pracownia.jsx" /* webpackChunkName: "component---src-pages-pracownia-jsx" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

